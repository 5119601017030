<template>
  <div>
    <question-header
      :title="question.title"
      :description="question.description"
      :help="question.help">
    </question-header>

    <b-form action="" @submit.prevent="submit">

      <p class="mb-4">
        <strong>Welche Umsatzgröße wollen Sie als Basis der Planung verwenden?</strong>
      </p>

      <b-form-radio-group class="ml-4 mb-5"
            @change="calculateTurnover"
            v-model="turnover_planning_attributes.selectedCalculationCase"
            stacked
            :options="options"></b-form-radio-group>

      <p class="mb-4">
        <strong>Wie verändern sich die Umsätze nach Ihrer Einschätzung gegenüber der gewählten Basis im laufenden Jahr sowie in den beiden Folgejahren (jeweils zum Vorjahr)?</strong>
      </p>

      <div class="d-flex justify-content-between">
        <b-form-group
          class="mb-4 mr-4"
          :invalid-feedback="question.invalid"
          :state="$v.turnover_planning_attributes.entwicklung_1.$invalid ? false : null"
          >
          <b-input-group size="lg">
            <b-form-input
              id="text-input"
              ref="planjahr0"
              type="text"
              placeholder="1. Planjahr"
              :state="$v.turnover_planning_attributes.entwicklung_1.$invalid ? false : null"
              v-model="$v.turnover_planning_attributes.entwicklung_1.$model"
              :formatter="percentFormatter"
              lazy-formatter
              autofocus
              @change="calculateTurnover"
            />
            <b-input-group-append is-text>
              <b-icon :icon="question.icon"></b-icon>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group
          class="mb-4 mr-4"
          :invalid-feedback="question.invalid"
          :state="$v.turnover_planning_attributes.entwicklung_2.$invalid ? false : null"
          >
          <b-input-group size="lg">
            <b-form-input
              id="text-input"
              ref="planjahr1"
              type="text"
              placeholder="2. Planjahr"
              :state="$v.turnover_planning_attributes.entwicklung_2.$invalid ? false : null"
              :formatter="percentFormatter"
              lazy-formatter
              v-model="$v.turnover_planning_attributes.entwicklung_2.$model"
              @change="calculateTurnover"
            />
            <b-input-group-append is-text>
              <b-icon icon="percent"></b-icon>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group
          class="mb-4"
          :invalid-feedback="question.invalid"
          :state="$v.turnover_planning_attributes.entwicklung_3.$invalid ? false : null"
          >
          <b-input-group size="lg" >
            <b-form-input
              id="text-input"
              ref="planjahr2"
              type="text"
              placeholder="3. Planjahr"
              :state="$v.turnover_planning_attributes.entwicklung_3.$invalid ? false : null"
              :formatter="percentFormatter"
              lazy-formatter
              v-model="$v.turnover_planning_attributes.entwicklung_3.$model"
              @change="calculateTurnover"
            />
            <b-input-group-append is-text>
              <b-icon icon="percent"></b-icon>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>

      <p class="text-muted">
        <em>Bitte tätigen Sie eine Angabe in Prozent: Rückläufige turnover_planning_attributes sind mit negativem Vorzeichen zu erfassen. Bei Stagnation ist der Wert '0' anzusetzen. Bitte berücksichtigen Sie auch die positiven Effekte etwaiger Investitionen in das Betriebsvermögen.</em>
      </p>

      <div class="mt-5">
        <p class="mb-4">
          <strong>Planung nach dem 3. Jahr</strong>
        </p>

        <p class="text-muted">
          <em>Für das jährliche Umsatzwachstum nach dem 3. Jahr haben wir die letzte Wachstumsrate fortgeschrieben. Wenn Sie diese verwenden wollen, brauchen Sie nichts weiter zu tun, ansonsten überschreiben Sie den Wert bitte.</em>
        </p>

        <b-form-group
          class="mb-4"
          :invalid-feedback="question.invalid"
          :state="$v.turnover_planning_attributes.entwicklung_4_plus.$invalid ? false : null"
          >
          <b-input-group size="lg" >
            <b-form-input
              id="text-input"
              ref="planjahr3"
              type="text"
              placeholder="Folgejahre"
              :state="$v.turnover_planning_attributes.entwicklung_4_plus.$invalid ? false : null"
              :formatter="percentFormatter"
              lazy-formatter
              v-model="$v.turnover_planning_attributes.entwicklung_4_plus.$model"
              @change="calculateTurnover"
            />
            <b-input-group-append is-text>
              <b-icon icon="percent"></b-icon>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="mt-5">
        <p class="mb-4">
          <strong>Auf Basis Ihrer Eingaben ergeben sich für die Folgejahre die folgenden Umsätze:</strong>
        </p>

        <b-table-simple class="w-80" hover responsive>
          <b-tbody>
            <b-tr>
              <b-td>1. Planjahr: </b-td>
              <b-td>{{ this.$n(entwicklung.planjahr_0, 'currency', 'de-DE') }}</b-td>
            </b-tr>
            <b-tr>
              <b-td>2. Planjahr: </b-td>
              <b-td>{{ this.$n(entwicklung.planjahr_1, 'currency', 'de-DE') }}</b-td>
            </b-tr>
            <b-tr>
              <b-td>3. Planjahr: </b-td>
              <b-td>{{ this.$n(entwicklung.planjahr_2, 'currency', 'de-DE') }}</b-td>
            </b-tr>
            <b-tr>
              <b-td>Nach dem 3. Planjahr: </b-td>
              <b-td>{{ this.$n(entwicklung.planjahr_3, 'currency', 'de-DE') }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

        <p class="text-muted">
          <sub><em>Eine detailliertere, auf Basis Ihrer Eingaben berechnete Gewinn- und Verlustrechnung für die Planjahre erhalten Sie in Ihrem individuellen Ergebnisreport (PRO).</em></sub>
        </p>
      </div>

      <question-footer
        @back="back"
        @next="submitAnswer"
        v-bind:v="$v"
      />
    </b-form>
  </div>
</template>

<script>
import QuestionType from '@/inc/QuestionType'
import { required, helpers } from 'vuelidate/lib/validators'

import QuestionHeader from '@/components/QuestionHeader.vue'
import QuestionFooter from '@/components/QuestionFooter.vue'

import { mapState } from 'vuex'

const decimal = helpers.regex('decimal', /^[-]?\d*(,\d+)?$/)

export default {
  extends: QuestionType,

  components: {
    QuestionHeader,
    QuestionFooter
  },

  data () {
    return {
      turnover_planning_attributes: {
        selectedCalculationCase: 'Letztes Ist-Jahr',
        entwicklung_1: null,
        entwicklung_2: null,
        entwicklung_3: null,
        entwicklung_4_plus: null,
        relevance_1: 1,
        relevance_2: 2,
        relevance_3: 3
      },
      entwicklung: {
        planjahr_0: 0,
        planjahr_1: 0,
        planjahr_2: 0,
        planjahr_3: 0
      }
    }
  },
  mounted() {
    if (this.currentAnswer) {
      this.turnover_planning_attributes = Object.assign({}, this.turnover_planning_attributes, this.currentAnswer)
      this.calculateTurnover()
    }
  },
  computed: {
    options() {
      return [
        {
          text: 'Letztes Ist-Jahr ( ' + this.$n(this.turnover, 'currency', 'de-DE') + ' )',
          value: "Letztes Ist-Jahr"
        },
        {
          text: 'Gewichteter Durchschnitt der Ist-Jahre ( ' + this.$n(this.weightedAverage, 'currency', 'de-DE') + ' )',
          value: "Gewichteter Durchschnitt Ist-Jahre"
        }
      ]
    },
    weightedAverage() {
      let u = this.answers.unternehmensdaten_guv

      if (!u) {
        return 0
      }

      return ((u.umsatzerloese_1 * 1) + (u.umsatzerloese_2 * 2) + (u.umsatzerloese_3 * 3)) / 6
    },
    turnover() {
      let u = this.answers.unternehmensdaten_guv

      return u ? u.umsatzerloese_3 : 0
    },
    baseValue() {
      const turnover = this.turnover

      let baseValue = turnover

      if (this.turnover_planning_attributes.selectedCalculationCase === 'Gewichteter Durchschnitt Ist-Jahre') {
        baseValue = this.weightedAverage
      }

      return baseValue
    },
    ...mapState('questions', {
      answers: state => state.answers
    }),
  },
  validations: {
    turnover_planning_attributes: {
      entwicklung_1: {
        required,
        decimal
      },
      entwicklung_2: {
        required,
        decimal
      },
      entwicklung_3: {
        required,
        decimal
      },
      entwicklung_4_plus: {
        required,
        decimal
      }
    }
  },
  methods: {
    calculateTurnover() {
      this.entwicklung.planjahr_0 = this.baseValue * (1 + (this.turnover_planning_attributes.entwicklung_1 / 100))
      this.entwicklung.planjahr_1 = this.entwicklung.planjahr_0 * (1 + (this.turnover_planning_attributes.entwicklung_2 / 100))
      this.entwicklung.planjahr_2 = this.entwicklung.planjahr_1 * (1 + (this.turnover_planning_attributes.entwicklung_3 / 100))
      this.entwicklung.planjahr_3 = this.entwicklung.planjahr_2 * (1 + (this.turnover_planning_attributes.entwicklung_4_plus / 100))

      return this.entwicklung
    },
    // calculateTurnoverInfinite() {
    //   let value = this.turnover_planning_attributes.planjahr_3.replace(',', '.')
    //   let cagr =  value / 100

    //   this.entwicklung.planjahr_3 = this.entwicklung.planjahr_2 * (1 + cagr)
    // },
    percentFormatter(value) {
      const newValue = value.replace(',', '.')
      const number = Number(newValue)
      if (!isNaN(number)) {
        return this.$n(value.replace(',', '.'), 'percent2', 'de-DE')
      }
      return value
    },
    submitAnswer () {
      this.answer(this.turnover_planning_attributes)
    }
  }
}
</script>
